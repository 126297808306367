import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import { differenceInDays, format } from 'date-fns';

function StravaPlanProgress({ target, progress, estimatedTime, startDate }) {
  const daysLeft = Math.max(differenceInDays(target.date, new Date()), 0);

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h5" component="h2">
            {`${target.name} - ${target.distance.toFixed(1)} km`}
          </Typography>
          <Typography variant="h6" component="h2">
            {`${format(target.date, 'do MMMM yyyy')} (${daysLeft} days left)`}
          </Typography>
          {new Date() < startDate ? (
            <Typography variant="h6" component="h2">
              {`Plan start date: ${format(startDate, 'do MMMM yyyy')}`}
            </Typography>
          ) : (
            <>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ height: 15 }}
                color="success"
              />
              <Typography variant="h6" component="h2">
                {`Training plan progress: ${progress.toFixed(0)}%`}
              </Typography>
            </>
          )}
          <Typography variant="h6" component="h2">
            {`Esitmated race time: ${estimatedTime}`}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

StravaPlanProgress.propTypes = {
  target: PropTypes.shape({
    name: PropTypes.string,
    distance: PropTypes.number,
    date: PropTypes.instanceOf(Date)
  }).isRequired,
  progress: PropTypes.number.isRequired,
  estimatedTime: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired
};

export default StravaPlanProgress;
