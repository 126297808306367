import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStrava } from '@fortawesome/free-brands-svg-icons';

import StravaActivityPicker from '../components/StravaActivityPicker';
import StravaActivityDetail from '../components/StravaActivityDetail';

import { getApiToken } from '../api/base';
import { userAuthorised, storeAuthInfo } from '../api/strava';

class Strava extends React.Component {
  constructor(props) {
    super(props);

    this.authorise = this.authorise.bind(this);
    this.storeAuth = this.storeAuth.bind(this);
    this.handleActivitySelected = this.handleActivitySelected.bind(this);

    // ToDo: Handle callback on API side?
    const { location } = this.props;
    if (location && location.search) {
      this.storeAuth();
    }

    this.state = {
      authorised: false,
      selectedActivity: null,
      selectedActivityType: null
    };
  }

  async componentDidMount() {
    const data = await userAuthorised();
    console.log(data);

    this.setState({
      authorised: data.authorised
    });
  }

  handleActivitySelected(activityId, activityType) {
    this.setState({
      selectedActivity: activityId,
      selectedActivityType: activityType
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async authorise() {
    // ToDo: Send redirect to API service and redirect from there back here.

    // const protocol = window.location.protocol;
    // const host = window.location.hostname;
    // const port = window.location.port;

    // const apiUrl = `${protocol}//${host}:${port}/api/Strava`;
    // const apiUrl = 'http://192.168.0.157:81/api/Strava';

    const token = await getApiToken();

    const loginUrl = `http://www.strava.com/oauth/authorize?client_id=47170&response_type=code&redirect_uri=${window.location.href}&approval_prompt=force&scope=read_all,activity:read_all&state=${token}`;
    window.location.replace(loginUrl);
  }

  async storeAuth() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const data = await storeAuthInfo({
      code: params.get('code'),
      scopes: params.get('scope'),
      state: params.get('state')
    });
    // console.log(data);
    const { protocol, hostname, port, pathname } = window.location;
    if (data.status && data.status === 'success') {
      const redirectUrl = `${protocol}//${hostname}:${port}${pathname}`;
      console.log(redirectUrl);
      window.location.replace(redirectUrl);
    }
  }

  render() {
    const { authorised, selectedActivity, selectedActivityType } = this.state;
    console.log(selectedActivity);
    return authorised ? (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <StravaActivityPicker onSelect={this.handleActivitySelected} />
        </Grid>
        <Grid item xs={12} lg={9}>
          {selectedActivity !== null && (
            <StravaActivityDetail
              activityId={selectedActivity}
              activityType={selectedActivityType}
            />
          )}
        </Grid>
      </Grid>
    ) : (
      <Button onClick={this.authorise}>
        <FontAwesomeIcon icon={faStrava} />
        <span style={{ marginLeft: '10px' }}>Authorise</span>
      </Button>
    );
  }
}

Strava.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default Strava;
