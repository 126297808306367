import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function TabSet({ tabNames, onTabChange }) {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    onTabChange(newValue);
  };

  return (
    <Tabs value={tabValue} onChange={handleTabChange}>
      {tabNames.map((tabName) => (
        <Tab label={tabName} />
      ))}
    </Tabs>
  );
}

TabSet.propTypes = {
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTabChange: PropTypes.func.isRequired
};

export default TabSet;
