import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import { Badge } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBicycle,
  faRunning,
  faWalking
} from '@fortawesome/free-solid-svg-icons';

import { getActivities } from '../api/strava';

class StravaActivityPicker extends React.Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);

    this.activityIcons = {
      Run: <FontAwesomeIcon icon={faRunning} />,
      Walk: <FontAwesomeIcon icon={faWalking} />,
      Ride: <FontAwesomeIcon icon={faBicycle} />
    };

    this.state = {
      activities: [],
      chosenDate: moment(),
      dayActivities: []
    };
  }

  componentDidMount() {
    const now = moment();
    const startOfMonth = moment({
      y: now.year(),
      M: now.month(),
      d: 1
    });

    this.getData(startOfMonth, now);
  }

  handleMonthChange(date) {
    const startOfNextMonth = moment({
      y: date.year(),
      M: date.month() + 1,
      d: 1
    });

    return this.getData(date, startOfNextMonth);
  }

  handleDateChange(date) {
    const { activities } = this.state;
    const dateFormat = 'YYYY-MM-DD';
    const dayActivities = activities.filter(
      (a) => a.start_date.format(dateFormat) === date.format(dateFormat)
    );

    this.setState({
      chosenDate: date,
      dayActivities
    });

    // if (dayActivities.length === 1) {
    //   let a = dayActivities[0];
    //   this.selectActivity(a.id, a.type);
    // }
  }

  async getData(startDate, endDate) {
    const data = await getActivities({
      after: startDate.unix(),
      before: endDate.unix()
    });

    const { chosenDate } = this.state;
    const activities = Object.values(data).map((a) => {
      const date = moment(a.start_date);
      return {
        ...a,
        start_date: date
      };
    });

    this.setState({
      activities
    });

    this.handleDateChange(chosenDate);
  }

  selectActivity(activityId, activityType) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(activityId, activityType);
    }
  }

  render() {
    const { chosenDate, dayActivities, activities } = this.state;

    return (
      <Paper>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            value={chosenDate}
            onChange={this.handleDateChange}
            onMonthChange={this.handleMonthChange}
            onYearChange={this.handleMonthChange}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const dateFormat = 'YYYY-MM-DD';
              const thisDayActivities = activities.filter(
                (a) =>
                  a.start_date.format(dateFormat) === day.format(dateFormat)
              );
              let content = null;

              if (isInCurrentMonth && thisDayActivities.length > 0) {
                if (thisDayActivities.length === 1) {
                  const activity = thisDayActivities[0];
                  content = this.activityIcons[activity.type];
                } else {
                  content = thisDayActivities.length;
                }
              }

              return (
                <Badge badgeContent={content} color="secondary">
                  {dayComponent}
                </Badge>
              );
            }}
          />
        </MuiPickersUtilsProvider>
        {dayActivities.length > 0 && (
          <>
            <Divider />
            <List>
              {dayActivities.map((a) => {
                const icon = this.activityIcons[a.type];
                const title = a.name;
                const subTitle = `${(a.distance / 1000).toFixed(
                  1
                )} km ${a.type.toLowerCase()}`;

                return (
                  <ListItem
                    key={a.id}
                    button
                    onClick={() => this.selectActivity(a.id, a.type)}
                  >
                    <ListItemAvatar>
                      <Avatar>{icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={title} secondary={subTitle} />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Paper>
    );
  }
}

StravaActivityPicker.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default StravaActivityPicker;
