import { makeRequest } from './base';

const userAuthorised = () => makeRequest('GET', 'threads/isAuthorised');
const storeAuthInfo = (code, scopes, state) => {
  const reqParams = new URLSearchParams({
    code,
    scopes,
    state
  });

  const endpoint = `threads/auth?${reqParams}`;
  return makeRequest('GET', endpoint);
};

export { userAuthorised, storeAuthInfo };
