import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core';

import Plot from 'react-plotly.js';

import { DataFrame } from 'data-forge';
import moment from 'moment';
import { formatDistance, parse } from 'date-fns';
import convert from 'convert';

import { getApiToken } from '../api/base';
import {
  userAuthorised,
  storeAuthInfo,
  getUserData,
  getDogs,
  getDogInfo,
  getActivityData,
  getPhoto
} from '../api/fitbark';
import { titleCase, getColourScale } from '../utils';

const styles = {};

const processActivityData = async (selectedDog, combined = true) => {
  const reqData = {
    dog_id: selectedDog.slug,
    start_date: moment().add(-90, 'days').format(),
    end_date: moment().format(),
    combined,
    from_source: false
  };

  try {
    const responseData = await getActivityData(reqData);
    return {
      responseData
    };
  } catch (err) {
    console.error(err);
    return {
      responseData: null
    };
  }
};

const getInfoData = async (selectedDog, fromSource = true) => {
  const reqData = {
    dog_id: selectedDog.slug,
    from_source: fromSource
  };

  if (fromSource) {
    reqData.start_date = moment().add(-7, 'days').format();
  } else {
    // reqData.start_date = moment('2021-12-05').format();
    reqData.start_date = moment().add(-90, 'days').format();
    reqData.end_date = moment().add(-1, 'days').format();
  }
  try {
    const responseData = await getDogInfo(reqData);

    return {
      fromSource,
      responseData
    };
  } catch (err) {
    console.error(err);
    return {
      fromSource,
      responseData: null
    };
  }
};

function FitBark({ location }) {
  const [loading, setLoading] = useState(true);
  const [authorised, setAuthorised] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [dogs, setDogs] = useState(null);
  // const [userPhoto, setUserPhoto] = useState(null);
  const [selectedDog, setSelectedDog] = useState(null);
  const [dogPhoto, setDogPhoto] = useState(null);
  const [activityData, setActivityData] = useState(null);
  // const [infoData, setInfoData] = useState(null);
  const [cachedInfoData, setCachedInfoData] = useState(null);
  const [plotWidth, setPlotWidth] = useState(null);

  const plotRef = useRef(null);

  const storeAuth = async () => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const reqData = {
      code: params.get('code'),
      scopes: params.get('scope'),
      state: params.get('state')
    };
    const data = await storeAuthInfo(reqData);
    if (data.status && data.status === 'success') {
      const { protocol, hostname, port, pathname } = window.location;
      const redirectUrl = `${protocol}//${hostname}:${port}${pathname}`;
      console.log(redirectUrl);
      window.location.replace(redirectUrl);
    }
  };

  const checkAuthorised = async () => {
    try {
      const data = await userAuthorised();
      if (data.authorised === true) {
        setAuthorised(data.authorised);
      } else {
        console.error('User not authorised');
        setAuthorised(false);
      }
    } catch (err) {
      console.error(err);
      setAuthorised(false);
    }
  };

  const processUserData = async () => {
    try {
      const responseData = await getUserData();
      setUserInfo(responseData);
    } catch (err) {
      console.error(err);
    }
  };

  const processDogs = async () => {
    try {
      const responseData = await getDogs();
      setDogs(responseData);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchPhoto = async (subjectType, slug) =>
    getPhoto({
      subject_type: subjectType,
      slug
    });

  const generateLabel = (row) => {
    const date = moment(row.date).format('ddd Do MMM HH:00');
    const elements = [date];

    const activityTypes = {
      play: 'min_play',
      active: 'min_active',
      rest: 'min_rest'
    };

    Object.keys(activityTypes).forEach((key) => {
      const activityType = activityTypes[key];
      const mins = row[activityType];
      elements.push(`  ${titleCase(key)}: ${mins} min${mins === 1 ? '' : 's'}`);
    });

    return elements.join('<br />');
  };

  const plotHeatMap = () => {
    let df = new DataFrame(activityData);
    df = df.generateSeries({
      date_only: (row) => moment(row.date).format('Y-MM-DD'),
      hour_only: (row) => moment(row.date).format('HH:00'),
      min_active_or_play: (row) => row.min_active + row.min_play,
      prop_active_or_play: (row) =>
        (100 * (row.min_active + row.min_play)) /
        (row.min_active + row.min_play + row.min_rest),
      label: generateLabel
    });

    df = df.where(
      (row) => moment(row.date) >= moment().subtract(21, 'days').startOf('day')
    );

    const trace = {
      x: df.getSeries('date_only').toArray(),
      y: df.getSeries('hour_only').toArray(),
      z: df.getSeries('prop_active_or_play').toArray(),
      text: df.getSeries('label').toArray(),
      hoverinfo: 'text',
      type: 'heatmap',
      colorscale: getColourScale(df, 'prop_active_or_play'),
      colorbar: {
        ticksuffix: '%'
      }
    };

    const traces = [trace];

    const layout = {
      width: plotWidth,
      showlegend: true,
      xaxis: {
        title: '',
        fixedrange: true
      },
      yaxis: {
        title: '',
        fixedrange: true,
        // autorange: 'reversed',
        tickmode: 'array',
        tickvals: [0, 4, 8, 12, 16, 20],
        ticktext: ['0:00', '4:00', '8:00', '12:00', '16:00', '20:00']
      },
      title: 'Hourly Activity',
      autosize: true
    };

    return <Plot data={traces} layout={layout} useResizeHandler />;
  };

  function plotActivityBars() {
    const df = new DataFrame(activityData)
      .generateSeries({
        date_date: (row) => moment(row.date).format('YYYY-MM-DD')
      })
      .groupBy((row) => row.date_date)
      .select((group) => ({
        date_date: group.first().date_date,
        hours_rest: group.deflate((row) => row.min_rest).sum() / 60,
        hours_active: group.deflate((row) => row.min_active).sum() / 60,
        hours_play: group.deflate((row) => row.min_play).sum() / 60
      }))
      .inflate()
      .generateSeries({
        restLabel: (row) => {
          const dateLabel = moment(row.date_date).format('ddd Do MMM');
          return `${dateLabel}<br />Rest: ${row.hours_rest.toFixed(1)} hours`;
        },
        activeLabel: (row) => {
          const dateLabel = moment(row.date_date).format('ddd Do MMM');
          return `${dateLabel}<br />Active: ${row.hours_active.toFixed(
            1
          )} hours`;
        },
        playLabel: (row) => {
          const dateLabel = moment(row.date_date).format('ddd Do MMM');
          return `${dateLabel}<br />Play: ${row.hours_play.toFixed(1)} hours`;
        }
      });

    const traces = [
      {
        x: df.getSeries('date_date').toArray(),
        y: df.getSeries('hours_rest').toArray(),
        text: df.getSeries('restLabel').toArray(),
        textposition: 'none',
        hoverinfo: 'text',
        name: 'Rest',
        type: 'bar',
        showlegend: true
      },
      {
        x: df.getSeries('date_date').toArray(),
        y: df.getSeries('hours_active').toArray(),
        text: df.getSeries('activeLabel').toArray(),
        textposition: 'none',
        hoverinfo: 'text',
        name: 'Active (non-play)',
        type: 'bar',
        showlegend: true
      },
      {
        x: df.getSeries('date_date').toArray(),
        y: df.getSeries('hours_play').toArray(),
        text: df.getSeries('playLabel').toArray(),
        textposition: 'none',
        hoverinfo: 'text',
        name: 'Play',
        type: 'bar',
        showlegend: true
      }
    ];

    const layout = {
      width: plotWidth,
      barmode: 'stack',
      showlegend: true,
      xaxis: {
        title: ''
      },
      yaxis: {
        title: 'Time [hours]',
        fixedrange: true,
        range: [0, 24]
      },
      title: 'Daily Activity',
      autosize: true,
      legend: {
        orientation: 'h'
      }
    };

    return <Plot data={traces} layout={layout} useResizeHandler />;
  }

  const plotBattery = () => {
    const df = new DataFrame(cachedInfoData).generateSeries({
      date: (row) => moment(row.last_sync).format('YYYY-MM-DD'),
      battery_percentage: (row) => row.battery_level / 100
    });

    const traces = [
      {
        x: df.getSeries('date').toArray(),
        y: df.getSeries('battery_percentage').toArray(),
        name: 'Battery Level',
        type: 'scatter',
        fill: 'tozeroy',
        showlegend: false
      }
    ];
    const layout = {
      title: 'Battery Level',
      xaxis: {
        title: ''
      },
      yaxis: {
        title: '',
        fixedrange: true,
        range: [0, 1],
        tickformat: ',.0%'
      },
      width: plotWidth,
      autosize: true
    };
    const config = {};

    return (
      <Plot data={traces} layout={layout} config={config} useResizeHandler />
    );
  };

  if (location && location.search) storeAuth();

  useEffect(() => {
    if (loading) checkAuthorised();

    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth, loading]);

  window.addEventListener('resize', () => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  useEffect(() => {
    if (authorised && !userInfo) {
      processUserData();
      processDogs();
    }
  }, [authorised, userInfo]);

  useEffect(() => {
    if (dogs && !selectedDog) {
      setSelectedDog(dogs.dog_relations[0].dog);
    }
  }, [dogs, selectedDog]);

  useEffect(() => {
    if (selectedDog && !dogPhoto) {
      const getDogPhoto = async () => {
        const photoData = await fetchPhoto('dog', selectedDog.slug);
        setDogPhoto(photoData);
      };
      getDogPhoto();
    }
  }, [selectedDog, dogPhoto]);

  useEffect(() => {
    if (selectedDog) {
      Promise.all([
        processActivityData(selectedDog, true),
        getInfoData(selectedDog, false)
      ])
        .then(([activityResult, infoResult]) => {
          setActivityData(activityResult.responseData.activity_series.records);
          setCachedInfoData(infoResult.responseData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedDog]);

  const progress = (height) => (
    <div
      style={{
        height,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={100} />
    </div>
  );

  // const setupImage = (encodedString, desc) => {
  //   const imageSource = `data:image/jpeg;base64,${encodedString}`
  //   return <img
  //     src={imageSource}
  //     alt={desc}
  //     width={150}
  //     style={{
  //       borderRadius: '5%'
  //     }}
  //   />
  // };

  const convertWeight = (value, unit) => {
    if (unit === 'lbs')
      return `${convert(value, 'pounds').to('kg').toFixed(1)} kg`;
    return `${value} ${unit}`;
  };

  return (
    <Grid
      container
      direction="row"
      alignContent="center"
      justifyContent="center"
      spacing={5}
    >
      {authorised === false && (
        <Grid item xs={12} md={3}>
          <Paper>
            <Button
              variant="contained"
              onClick={async () => {
                const token = await getApiToken();
                const clientId =
                  '431aa24f6a8311bf9074326ecd442651f05d916760ab0beb0d07118bd1b4c92a';
                const redirectUri = `${window.location.href}`;
                const scope = 'partners';
                const responseType = 'code';
                const authUrl = `https://app.fitbark.com/oauth/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${token}`;
                window.location.replace(authUrl);
              }}
            >
              Login
            </Button>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12} md={5}>
        <Card>
          {dogPhoto ? (
            <CardActionArea style={{ display: 'flex' }}>
              <CardMedia
                component="img"
                alt={selectedDog.name}
                // height="160"
                image={`data:image/jpeg;base64,${dogPhoto.image.data}`}
                title={selectedDog.name}
                style={{ width: '50%' }}
              />
              <CardContent style={{ width: '50%' }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {selectedDog.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`Age: ${formatDistance(
                    new Date(),
                    parse(selectedDog.birth, 'yyyy-MM-dd', new Date())
                  )} old`}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`Weight: ${convertWeight(
                    selectedDog.weight,
                    selectedDog.weight_unit
                  )}`}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`Battery: ${selectedDog.battery_level}%`}
                </Typography>
              </CardContent>
            </CardActionArea>
          ) : (
            progress('224.2px')
          )}
        </Card>
      </Grid>
      {authorised && (
        <Grid item xs={12} md={8}>
          <Paper ref={plotRef}>
            {activityData === null ? progress('456px') : plotHeatMap()}
          </Paper>
        </Grid>
      )}
      {authorised && (
        <Grid item xs={12} md={8}>
          <Paper>
            {activityData === null ? progress('456px') : plotActivityBars()}
          </Paper>
        </Grid>
      )}
      {authorised && (
        <Grid item xs={12} md={8}>
          <Paper>
            {cachedInfoData === null ? progress('456px') : plotBattery()}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

FitBark.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};
  
  export default withStyles(styles)(FitBark);