import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import {
  getApiToken,
  removeGoogleToken,
  removeAllTokens,
  saveGoogleLogin
} from '../../api/base';

function GoogleAuthButton({ onAuthorisationChanged }) {
  const [google, setGoogle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  const signInDiv = useRef(null);
  const signOutDiv = useRef(null);

  const promptForGoogleLogin = () => {
    removeGoogleToken();

    console.log('Prompting for login');
    window.google.accounts.id.prompt();
  };

  const handleGoogleLogin = async (incomingResponse) => {
    try {
      await saveGoogleLogin(incomingResponse);

      setLoggedIn(true);
      onAuthorisationChanged(true);
    } catch (err) {
      console.error(err);
      promptForGoogleLogin();
    }
  };

  const handleGoogleLogout = () => {
    removeAllTokens();

    setLoggedIn(false);
    onAuthorisationChanged(false);
  };

  useEffect(() => {
    if (!google) {
      window.onload = () => {
        if (window.google) setGoogle(window.google);
      };
    }
  });

  useEffect(() => {
    if (google && loading) {
      console.log('Initializing Google sign in');

      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleLogin,
        ux_mode: 'popup',
        auto_select: true,
        context: 'use',
        use_fedcm_for_prompt: true
      });

      setLoading(false);
    }
  }, [google, loading]);

  useEffect(() => {
    if (google && !loading) {
      if (!loggedIn) {
        getApiToken().then((tokenData) => {
          if (tokenData) {
            setLoggedIn(true);
            onAuthorisationChanged(true);
          } else if (window.localStorage.google_token) {
            console.log('Re-using cached Google creds');
            const googleCreds = JSON.parse(window.localStorage.google_token);
            if (googleCreds) handleGoogleLogin({ credential: googleCreds });
          } else {
            promptForGoogleLogin();
          }
        });
      }
    }

    if (google && !loading && !loggedIn) {
      google.accounts.id.renderButton(signInDiv.current, {
        theme: 'filled_black',
        text: 'signin',
        shape: 'pill'
      });

      if (signOutDiv?.current) {
        signOutDiv.current.onClick = () => {
          console.log('Log out');
        };
      }
    }
  }, [google, loading, loggedIn]);

  if (loggedIn)
    return (
      <Button
        variant="contained"
        className="g_id_signout"
        ref={signOutDiv}
        onClick={handleGoogleLogout}
      >
        Sign Out
      </Button>
    );

  return <div ref={signInDiv} />;
}

GoogleAuthButton.propTypes = {
  onAuthorisationChanged: PropTypes.func.isRequired
};

GoogleAuthButton.defaultProps = {};

export default GoogleAuthButton;
