import React, { useState, useEffect, useRef } from "react";

import {
  Grid, Paper,
  TextField, Typography, Button,
  CircularProgress
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { DataFrame } from "data-forge";
import moment from "moment";
import Plot from "react-plotly.js";

import { getMileage, recordMileage } from '../api/car';

function Car() {
  const [pageLoading, setPageLoading] = useState(true);
  const [registration] = useState('KS17GVP');
  const [garages, setGarages] = useState([
    { label: 'Shell Great Oldbury' },
    { label: 'Tesco Stroud' },
    { label: 'Tesco Gloucester' },
    { label: 'BP Longbridge Deverill' },
    { label: 'Tesco Shaftesbury' }
  ]);

  const [litres, setLitres] = useState(null);
  const [miles, setMiles] = useState(null);
  const [cost, setCost] = useState(null);
  const [garage, setGarage] = useState(null);
  const [notes, setNotes] = useState(null);

  const [history, setHistory] = useState([]);

  const plotRef = useRef(null);
  const [plotWidth, setPlotWidth] = useState(null);

  const submitMileage = async (data) => {
    const responseData = await recordMileage(data);
    console.log(responseData);
  };

  const getMileageHistory = async () => {
    const data = await getMileage({
      registration,
      start_date: new Date(2010, 1, 1),
      end_date: new Date()
    });

    const df = new DataFrame(data)
      .generateSeries({
        date: (row) => moment(row.filling_date).format('YYYY-MM-DD')
      })
      .orderBy((row) => row.date);

    setHistory(df);
    setGarages(
      data
        .map((row) => row.petrol_station)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((label) => ({
          label
        }))
    );
  };

  const handleSubmit = async () => {
    const record = {
      registration,
      litres,
      miles_driven: miles,
      price_per_litre: cost,
      petrol_station: garage,
      filling_date: new Date(),
      notes
    };

    console.log(record);
    await submitMileage(record);
    setPageLoading(true);
    await getMileageHistory(registration);
    setPageLoading(false);
  };

  const plotFuelEconomy = (df) => {
    const traces = [
      {
        x: df.getSeries('filling_date').toArray(),
        y: df.getSeries('mpg').toArray(),
        name: 'Fuel Economy [mpg]',
        type: 'scatter',
        fill: 'tozeroy',
        showlegend: false,
        hovertemplate: '%{y:.2f} mpg',
        hoverinfo: 'text'
      }
    ];
    const layout = {
      title: '',
      xaxis: {
        title: ''
      },
      yaxis: {
        title: 'Fuel Economy [mpg]'
      },
      width: plotWidth,
      autosize: true
    };
    const config = {};

    return <Plot data={traces} layout={layout} config={config} />;
  };

  const plotCostPerMile = (df) => {
    const traces = [
      {
        x: df.getSeries('filling_date').toArray(),
        y: df.getSeries('cost_per_mile').toArray(),
        name: 'Fuel Cost [pence / mile]',
        type: 'scatter',
        fill: 'tozeroy',
        showlegend: false,
        hovertemplate: '%{y:.1f}p',
        hoverinfo: 'text'
      }
    ];
    const layout = {
      title: '',
      xaxis: {
        title: ''
      },
      yaxis: {
        title: 'Fuel Cost [pence / mile]'
      },
      width: plotWidth,
      autosize: true
    };
    const config = {};

    return <Plot data={traces} layout={layout} config={config} />;
  };

  const plotFuelEconomyHistogram = (df) => {
    const traces = [
      {
        x: df.getSeries('mpg').toArray(),
        name: 'Fuel Economy [mpg]',
        type: 'histogram',
        showlegend: false,
        hovertemplate: '%{x:.2f} mpg',
        hoverinfo: 'text'
      }
    ];
    const layout = {
      title: '',
      xaxis: {
        title: 'Fuel Economy [mpg]'
      },
      yaxis: {
        title: 'Number of Refills'
      },
      width: plotWidth,
      autosize: true
    };
    const config = {};

    return <Plot data={traces} layout={layout} config={config} />;
  };

  useEffect(async () => {
    await getMileageHistory(registration);
    setPageLoading(false);
  }, [registration]);

  useEffect(() => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth, plotRef]);

  window.addEventListener('resize', () => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  return (
    <Grid
      container
      direction="row"
      alignContent="center"
      justifyContent="center"
      spacing={5}
    >
      <Grid item xs={12} sm={4}>
        <Paper style={{ padding: 10 }}>
          <Typography variant="h4">Mileage Input</Typography>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="litres"
              label="Litres Bought"
              type="number"
              required
              onChange={(event) => setLitres(parseFloat(event.target.value))}
            />
            <TextField
              id="price"
              label="Pence per Litre"
              type="number"
              required
              onChange={(event) => setCost(parseFloat(event.target.value))}
            />
            <TextField
              id="miles"
              label="Miles Driven"
              type="number"
              required
              onChange={(event) => setMiles(parseFloat(event.target.value))}
            />
            <Autocomplete
              id="garage"
              freeSolo
              options={garages}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Garage"
                  margin="normal"
                  style={{ width: 207, margin: 0 }}
                />
              )}
              onChange={(event, reason) => {
                if (reason && reason.label) {
                  const chosenGarage = reason.label;
                  setGarage(chosenGarage);
                }
              }}
              onInputChange={(event, value) => {
                if (value) setGarage(value);
              }}
            />
            <TextField
              id="notes"
              label="Notes"
              onChange={(event) => setNotes(event.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper ref={plotRef}>
          {pageLoading ? (
            <CircularProgress size={100} />
          ) : (
            plotFuelEconomy(history)
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper ref={plotRef}>
          {pageLoading ? (
            <CircularProgress size={100} />
          ) : (
            plotCostPerMile(history)
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper ref={plotRef}>
          {pageLoading ? (
            <CircularProgress size={100} />
          ) : (
            plotFuelEconomyHistogram(history)
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Car;
