import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog } from '@fortawesome/free-solid-svg-icons';
import GenericAuthButton from './GenericAuth';

import { userAuthorised, storeAuthInfo } from '../../api/fitbark';

function FitbarkAuthButton({ location, displayLogout }) {
  const clientId =
    '431aa24f6a8311bf9074326ecd442651f05d916760ab0beb0d07118bd1b4c92a';
  const scope = 'partners';
  const responseType = 'code';

  return (
    <GenericAuthButton
      name="Fitbark"
      userAuthorisedFunction={userAuthorised}
      storeAuthFunction={storeAuthInfo}
      stateKey="fitbark"
      authUrlFunction={(redirectUri, state) =>
        `https://app.fitbark.com/oauth/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`
      }
      colourName="fitbark"
      icon={<FontAwesomeIcon icon={faDog} />}
      location={location}
      displayLogout={displayLogout}
    />
  );
}

FitbarkAuthButton.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  displayLogout: PropTypes.bool
};

FitbarkAuthButton.defaultProps = {
  location: null,
  displayLogout: true
};

export default FitbarkAuthButton;
