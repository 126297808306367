import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThreads } from '@fortawesome/free-brands-svg-icons';
import GenericAuthButton from './GenericAuth';

import { userAuthorised, storeAuthInfo } from '../../api/threads';

function ThreadsAuthButton({ location, displayLogout }) {
  const clientId = '1001693734879179';
  const scope = ['threads_basic', 'threads_content_publish'].join(',');
  const responseType = 'code';

  return (
    <GenericAuthButton
      name="Threads"
      userAuthorisedFunction={userAuthorised}
      storeAuthFunction={storeAuthInfo}
      stateKey="threads"
      authUrlFunction={(redirectUri, state) =>
        `https://threads.net/oauth/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`
      }
      colourName="threads"
      icon={<FontAwesomeIcon icon={faThreads} />}
      location={location}
      displayLogout={displayLogout}
    />
  );
}

ThreadsAuthButton.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  displayLogout: PropTypes.bool
};

ThreadsAuthButton.defaultProps = {
  location: null,
  displayLogout: true
};

export default ThreadsAuthButton;
