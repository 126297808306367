import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import NextPlanIcon from '@mui/icons-material/NextPlan';

function StravaActivityStatusIcon({ run, i }) {
  const selectIconComponent = () => {
    if (run.metTarget)
      return <CheckCircleIcon color="success" fontSize="small" />;
    if (run.skipped) return <NextPlanIcon color="warning" fontSize="small" />;
    if (run.timeInMins) return <CircleIcon color="success" fontSize="small" />;
    return <CircleOutlinedIcon fontSize="small" />;
  };

  return (
    <Tooltip
      key={i}
      title={
        run.targetRange
          ? `${run.targetRange[0]} - ${run.targetRange[1]} mins`
          : '(no target)'
      }
      enterTouchDelay={0}
      arrow
      placement="top"
    >
      {selectIconComponent()}
    </Tooltip>
  );
}

StravaActivityStatusIcon.propTypes = {
  run: PropTypes.shape({
    timeInMins: PropTypes.number,
    metTarget: PropTypes.bool,
    targetRange: PropTypes.arrayOf(PropTypes.number),
    skipped: PropTypes.bool
  }).isRequired,
  i: PropTypes.number.isRequired
};

export default StravaActivityStatusIcon;
