import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStrava } from '@fortawesome/free-brands-svg-icons';

import GenericAuthButton from './GenericAuth';

import { userAuthorised, storeAuthInfo } from '../../api/strava';

function StravaAuthButton({ location, displayLogout }) {
  return (
    <GenericAuthButton
      name="Strava"
      userAuthorisedFunction={userAuthorised}
      storeAuthFunction={storeAuthInfo}
      stateKey="strava"
      authUrlFunction={(redirectUri, state) =>
        `http://www.strava.com/oauth/authorize?client_id=47170&response_type=code&redirect_uri=${redirectUri}&approval_prompt=force&scope=read_all,activity:read_all&state=${state}`
      }
      colourName="strava"
      icon={<FontAwesomeIcon icon={faStrava} />}
      location={location}
      displayLogout={displayLogout}
    />
  );
}

StravaAuthButton.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  displayLogout: PropTypes.bool
};

StravaAuthButton.defaultProps = {
  location: null,
  displayLogout: true
};

export default StravaAuthButton;
