import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { sub } from 'date-fns';

function ApiWebSocket({
  url,
  messageReceived,
  updateFrequency,
  reconnectFrequency,
  catchupTime,
  queryParams,
  showIndicator
}) {
  const [initialDate] = useState(new Date());

  const { lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    url,
    {
      queryParams: {
        start_date: sub(initialDate, { minutes: catchupTime }).toJSON(),
        update_frequency: updateFrequency,
        ...queryParams
      },
      share: false,
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: reconnectFrequency * 1000,
      // reconnectInterval: (attemptNumber) => {
      //   console.log(attemptNumber);
      //   return Math.min(2 ** attemptNumber, 10) * 1000;
      // },
      onReconnectStop: (numberAttempted) =>
        console.log(`Abandoning websocket after ${numberAttempted} attempts.`),
      onClose: (event) => console.log(event),
      onError: (event) => console.log(event),
      retryOnError: false
    },
    true
  );

  useEffect(() => {
    console.log(`Connection state changed to ${ReadyState[readyState]}`);
  }, [readyState]);

  useEffect(() => {
    if (!lastJsonMessage) {
      console.log(`Empty message: ${lastJsonMessage}`);
      return;
    }

    if (lastJsonMessage.error) {
      console.error(lastJsonMessage);
      getWebSocket().close();
      // const reconnectDate = add(new Date(), { minutes: 1 });
      // console.log(`Retrying at ${reconnectDate}`);
      // setWsReconnectDate(reconnectDate);
      return;
    }

    messageReceived(lastJsonMessage);
  }, [lastJsonMessage]);

  const getStatusEmoji = (status) => {
    switch (status) {
      case ReadyState.CONNECTING:
        return '🟡';
      case ReadyState.OPEN:
        return '🟢';
      case ReadyState.CLOSING:
        return '🟠';
      case ReadyState.CLOSED:
        return '🔴';
      case ReadyState.UNINSTANTIATED:
      default:
        return '⚪';
    }
  };

  return showIndicator && <div>{getStatusEmoji(readyState)}</div>;
}

ApiWebSocket.propTypes = {
  url: PropTypes.string.isRequired,
  messageReceived: PropTypes.func.isRequired,
  updateFrequency: PropTypes.number,
  reconnectFrequency: PropTypes.number,
  catchupTime: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: PropTypes.object,
  showIndicator: PropTypes.bool
};

ApiWebSocket.defaultProps = {
  updateFrequency: 60,
  reconnectFrequency: 60,
  catchupTime: 60,
  queryParams: {},
  showIndicator: true
};

export default ApiWebSocket;
