import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Link } from '@mui/material';

function StravaSuggestedActivity({ route }) {
  return (
    <Stack useFlexGap>
      {route && Object.keys(route).length > 0 && (
        <Link
          href={`https://www.strava.com/routes/${route.id_str}`}
          target="_blank"
          underline="none"
          color="#fc5200"
        >
          <img
            src={route['map_urls.retina_url']}
            alt={route.name}
            width="100%"
          />
        </Link>
      )}
    </Stack>
  );
}

StravaSuggestedActivity.propTypes = {
  route: PropTypes.shape({
    id_str: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    distance: PropTypes.number.isRequired,
    estimated_moving_time: PropTypes.number.isRequired,
    'map_urls.retina_url': PropTypes.string.isRequired,
    estimatedTime: PropTypes.number.isRequired
  })
};

StravaSuggestedActivity.defaultProps = {
  route: null
};

export default StravaSuggestedActivity;
