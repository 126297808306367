import React from 'react';
import PropTypes from 'prop-types';

import FitbitIcon from '@mui/icons-material/Fitbit';

import GenericAuthButton from './GenericAuth';

import { getAuthUrl, isAuthorised, storeAuthInfo } from '../../api/fitbit';

function FitbitAuthButton({ location, displayLogout }) {
  return (
    <GenericAuthButton
      name="Fitbit"
      userAuthorisedFunction={isAuthorised}
      storeAuthFunction={storeAuthInfo}
      stateKey="fitbit"
      authUrlFunction={getAuthUrl}
      colourName="fitbit"
      icon={<FitbitIcon />}
      location={location}
      displayLogout={displayLogout}
    />
  );
}

FitbitAuthButton.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  displayLogout: PropTypes.bool
};

FitbitAuthButton.defaultProps = {
  location: null,
  displayLogout: true
};

export default FitbitAuthButton;
