import { getISOWeek, getUnixTime, sub, parseJSON } from 'date-fns';

import { getActivities, getRoutes } from '../api/strava';

const didMeetTarget = (run, time) => {
  if (!time) return undefined;
  if (!run?.timeInMins) return false;
  return run.timeInMins > time * 0.99;
};

const augmentActivityData = (activity) => {
  const date = parseJSON(activity.start_date);
  const week = getISOWeek(date);

  return {
    ...activity,
    date,
    week,
    timeInMins: Math.ceil(activity.moving_time / 60),
    averagePace: 1000 / (activity.average_speed * 60)
  };
};

export const combinePlanWithRuns = (
  trainingPlan,
  activities,
  displayStartWeek,
  targetWeek
) => {
  const runActivities = Object.values(activities)
    .filter((item) => item.type === 'Run')
    .toReversed();
  const runsByWeek = {};
  runActivities.forEach((activity) => {
    const run = augmentActivityData(activity);
    const { week } = run;

    if (week in runsByWeek) {
      runsByWeek[week].push(run);
    } else {
      runsByWeek[week] = [run];
    }
  });

  const planWithRuns = {};
  for (let week = displayStartWeek; week <= targetWeek; week += 1) {
    const runs = week in runsByWeek ? runsByWeek[week] : [];
    const times = week in trainingPlan ? trainingPlan[week] : [];

    let offset = 0;

    const combined = Array.from(
      Array(Math.max(times.length, runs.length)),
      (_, i) => {
        const plannedTime = times[i];
        const matchedRun = plannedTime?.skipped ? {} : runs[i - offset];
        if (plannedTime?.skipped) offset += 1;
        return {
          ...matchedRun,
          targetMins: plannedTime ? plannedTime.min : undefined,
          targetRange: plannedTime
            ? [plannedTime.min, plannedTime.max]
            : undefined,
          metTarget: didMeetTarget(
            matchedRun,
            plannedTime ? plannedTime.min : undefined
          ),
          skipped: plannedTime?.skipped ?? false
        };
      }
    );

    planWithRuns[week] = combined;
  }

  return planWithRuns;
};

export const getRuns = async (after, before) => {
  const activities = await getActivities({
    after,
    before
  });
  return Object.values(activities).filter(
    (activity) => activity.type === 'Run'
  );
};

export const getRecentRuns = async () => {
  const results = await getRuns(
    getUnixTime(sub(new Date(), { months: 1 })),
    getUnixTime(new Date())
  );
  if (Object.values(results).length > 0)
    return Object.values(results).map((run) => augmentActivityData(run));
  return null;
};

export const getRunningRoutes = async () => {
  const routes = await getRoutes();
  return routes?.filter((route) => route.type === 2);
};

export const getEventActivityById = (activities, eventId) => {
  const matchingActivities = Object.values(activities).filter(
    (activity) => activity.id === eventId
  );
  if (matchingActivities.length === 0) return null;

  return matchingActivities[0];
};

export const getEventActivityByName = (activities, eventName) => {
  const matchingActivities = Object.values(activities).filter(
    (activity) => activity.name === eventName
  );
  if (matchingActivities.length === 0) return null;

  return matchingActivities[0];
};

export const bestRunPace = (runs) =>
  Math.min(
    ...runs.filter((run) => run.averagePace).map((run) => run.averagePace)
  );

export const getLongestRun = (runs) => {
  const longestDistance = Math.max(
    ...runs.filter((run) => run.distance).map((run) => run.distance)
  );
  return runs.find((run) => run.distance === longestDistance);
};

export const estimateTime = (distance, runAveragePace) =>
  1.06 * runAveragePace * distance;

export const calculateRecentAveragePace = async () => {
  const latestRuns = await getRecentRuns();

  const batchLatestRuns = latestRuns.slice(0, 3);
  const batchAveragePace =
    batchLatestRuns
      .map((run) => run.averagePace)
      .reduce((sum, value) => sum + value, 0) / batchLatestRuns.length;

  return batchAveragePace;
};

export const findRoutesForTime = (
  runningRoutes,
  targetRange,
  targetName,
  pace
) => {
  const [minTime, maxTime] = targetRange;

  const candidateRoutes = runningRoutes
    .map((route) => ({
      ...route,
      estimatedTime: (route.distance / 1000) * pace
    }))
    .filter(
      (route) =>
        route.name !== targetName &&
        route.estimatedTime &&
        route.estimatedTime > minTime &&
        route.estimatedTime < maxTime * 1.1
    )
    .toSorted((a, b) => a.estimatedTime - b.estimatedTime);
  // ToDo: Expand polyline to check for location and whether route is circular
  return candidateRoutes;
};
