import React from 'react';
import PropTypes from 'prop-types';

import Slide from '@mui/material/Slide';

function TabPanel({ index, value, children }) {
  return (
    <Slide direction="left" in={value === index} mountOnEnter unmountOnExit>
      <div role="tabpanel" hidden={value !== index}>
        {value === index && children}
      </div>
    </Slide>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default TabPanel;
