import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb as faLightbulbOn } from '@fortawesome/free-regular-svg-icons';
import { faLightbulb as faLightbulbOff } from '@fortawesome/free-solid-svg-icons';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { hexToMired, hexToHSV, hueSatBriToHex } from '../utils';
import { getHueData, sendHueCommand, sendParticleCommand } from '../api/home';

const styles = {
  root: {
    flexGrow: 1
  },
  button: {
    height: '80px',
    width: '150px',
    fontSize: '14pt',
    backgroundColor: '#e0e0e0'
  },
  colourPicker: {
    width: '100%',
    height: '100%',
    border: 'none'
  }
};

const lockColour = '#000000';
const unlockColour = '#ffa05a';

const backlightPresets = [
  {
    id: 'unlock',
    name: 'Unlock',
    icon: faLightbulbOn
  },
  {
    id: 'lock',
    name: 'Lock',
    icon: faLightbulbOff
  }
];

const huePresets = [
  {
    id: 'on',
    name: 'On',
    icon: faLightbulbOn
  },
  {
    id: 'off',
    name: 'Off',
    icon: faLightbulbOff
  }
];

const hueGroupId = 7;

function Desk({ classes }) {
  const [requestPending, setRequestPending] = useState(false);

  const [backlightColour, setBacklightColour] = useState(unlockColour);
  const [hueColour, setHueColour] = useState(unlockColour);

  const sendPhotonCommand = async (name, value) => {
    const data = {
      category: name,
      command: value
    };

    console.log(data);

    if (!requestPending) {
      setRequestPending(true);

      try {
        const responseData = await sendParticleCommand(data);

        console.log(responseData);
        if (name === 'backlight') {
          if (value === 'lock') {
            setBacklightColour(lockColour);
          } else if (value === 'unlock') {
            setBacklightColour(unlockColour);
          } else {
            setBacklightColour(value);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setRequestPending(false);
      }
    }
  };

  const handleBacklightColourChange = (event) => {
    const colour = event.target.value;
    sendPhotonCommand('backlight', colour);
  };

  const getHueColour = async () => {
    const data = await getHueData();
    const group = data.find((g) => g.api_id === `${hueGroupId}`);
    if (group) {
      console.log(group);
      if (!requestPending) setRequestPending(true);
      // const colourMode = group['action.colormode'];
      const hue = parseInt((group['action.hue'] * 360) / 65535);
      const sat = group['action.sat'] / 254;
      const bri = group['action.bri'] / 254;
      const hex = hueSatBriToHex(hue, sat, bri);
      setHueColour(hex);
      setRequestPending(false);
    }
  };

  const sendHueGroupCommand = async (command, value) => {
    console.log(`Command: ${command}, Value: ${value}`);

    const hueState = {
      on: true
    };

    if (command === 'off') hueState.on = false;
    if (command === 'colour') {
      hueState.ct = hexToMired(value);

      const hsv = hexToHSV(value);
      hueState.hue = parseInt((hsv[0] * 65535) / 360);
      hueState.sat = parseInt(hsv[1] * 254);
    }

    if (!requestPending) {
      try {
        await sendHueCommand({
          group_id: hueGroupId,
          state: hueState
        });
        getHueColour();
      } catch (err) {
        console.error(err);
      } finally {
        setRequestPending(false);
      }
    }
  };

  const handleHueColourChange = (event) => {
    const colour = event.target.value;
    sendHueGroupCommand('colour', colour);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs>
        <h3>Backlight</h3>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          {backlightPresets.map((preset) => (
            <Grid key={preset.id} item xs>
              <Button
                className={classes.button}
                variant="contained"
                startIcon={<FontAwesomeIcon icon={preset.icon} />}
                onClick={() => sendPhotonCommand('backlight', preset.id)}
              >
                {preset.name}
              </Button>
            </Grid>
          ))}
          <Grid key="backlightColour" item xs>
            <Paper className={classes.button}>
              <input
                id="colourPicker"
                type="color"
                className={classes.colourPicker}
                value={backlightColour}
                onChange={handleBacklightColourChange}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <h3>Hue</h3>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          {huePresets.map((preset) => (
            <Grid key={preset.id} item xs>
              <Button
                className={classes.button}
                variant="contained"
                startIcon={<FontAwesomeIcon icon={preset.icon} />}
                onClick={() => sendHueGroupCommand(preset.id)}
              >
                {preset.name}
              </Button>
            </Grid>
          ))}
          <Grid key="hueColour" item xs>
            <Paper className={classes.button}>
              <input
                id="colourPicker"
                type="color"
                className={classes.colourPicker}
                value={hueColour}
                onChange={handleHueColourChange}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Desk.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    colourPicker: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(Desk);
