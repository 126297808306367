import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

import StravaActivityStatusIcon from './StravaActivityStatusIcon';

function StravaWeekRuns({
  week,
  runs,
  bestPace,
  targetDistance,
  defaultExpanded
}) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <Accordion key={`card-week-${week}`} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
        // sx={{
        //   cursor: 'unset !important'
        // }}
      >
        <Grid container spacing={2} width="100%" justifyContent="space-between">
          <Grid xs={4}>
            <Typography variant="h6" component="h2" gutterBottom>
              {`Week ${week}`}
            </Typography>
          </Grid>
          <Grid xs={4}>
            {runs.length > 0 &&
              runs.map(
                (run, i) =>
                  run.metTarget !== undefined && (
                    <StravaActivityStatusIcon run={run} i={i} />
                  )
              )}
          </Grid>
          <Grid xs={4}>
            {runs.length > 0 && (
              <Typography variant="h6" component="h3">
                {bestPace && `${bestPace} / km`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {runs.length > 0 ? (
          runs.toReversed().map(
            (run) =>
              run.date && (
                <Grid container spacing={3} key={`strava-row-${run.id}`}>
                  <Grid xs={3} alignSelf="start">
                    <Typography variant="body1" noWrap>
                      {format(run.date, 'EEE do')}
                    </Typography>
                  </Grid>
                  <Grid xs={6} alignSelf="start">
                    <>
                      <LinearProgress
                        variant="determinate"
                        value={100 * (run.distance / 1000 / targetDistance)}
                        sx={{ height: 15 }}
                      />
                      <Link
                        href={`https://www.strava.com/activities/${run.id}`}
                        target="_blank"
                        underline="none"
                        color="#fc5200"
                      >
                        <Typography>{`${run.name} - ${(
                          run.distance / 1000
                        ).toFixed(1)} km`}</Typography>
                      </Link>
                    </>
                  </Grid>
                  <Grid xs={3} alignSelf="start">
                    <Typography>{`${run.timeInMins.toFixed(
                      0
                    )} mins`}</Typography>
                  </Grid>
                </Grid>
              )
          )
        ) : (
          <Typography fontStyle="italic" color="GrayText">
            No runs recorded so far
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

StravaWeekRuns.propTypes = {
  week: PropTypes.number.isRequired,
  runs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      start_date: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      week: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      distance: PropTypes.number,
      moving_time: PropTypes.number,
      timeInMins: PropTypes.number,
      targetMins: PropTypes.number,
      metTarget: PropTypes.bool,
      average_speed: PropTypes.number,
      averagePace: PropTypes.number,
      average_heartrate: PropTypes.number
    })
  ),
  bestPace: PropTypes.string,
  targetDistance: PropTypes.number.isRequired,
  defaultExpanded: PropTypes.bool
};

StravaWeekRuns.defaultProps = {
  runs: [],
  bestPace: '',
  defaultExpanded: true
};

export default StravaWeekRuns;
