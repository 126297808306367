import { makeRequest } from './base';

export const generateText = (modelName, systemInstruction, prompt) => {
  const group = modelName === 'gpt-4o' ? 'openai' : 'gemini';
  return makeRequest('POST', `llm/${group}/generate-text`, {
    modelName,
    systemInstruction,
    prompt
  });
};

export const placeholder = null;
