import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStrava } from '@fortawesome/free-brands-svg-icons';

import { parseJSON, format } from 'date-fns';

function StravaActivitySummary({ eventData }) {
  const secondsToTimeString = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const mins = Math.floor(totalSeconds / 60 - 60 * hours);
    const seconds = totalSeconds - 3600 * hours - 60 * mins;
    return `${hours}:${mins.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h5" component="h2">
            {eventData.name}
          </Typography>
          <Typography variant="h6" component="h2">
            {format(parseJSON(eventData.start_date), 'do MMMM yyyy H:mm a')}
          </Typography>
          <Typography variant="h6" component="h2">
            {`Recorded distance: ${(eventData.distance / 1000).toFixed(1)} km`}
          </Typography>
          <Typography variant="h6" component="h2">
            {`Recorded moving time: ${secondsToTimeString(
              eventData.moving_time
            )}`}
          </Typography>
          <Typography variant="h6" component="h2">
            {`Average heart rate: ${eventData.average_heartrate.toFixed(
              0
            )} bpm`}
          </Typography>
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faStrava} />}
            color="strava"
            href={`https://www.strava.com/activities/${eventData.id}/overview`}
            target="_blank"
          >
            Go to activity
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

StravaActivitySummary.propTypes = {
  eventData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    distance: PropTypes.number,
    start_date: PropTypes.string,
    moving_time: PropTypes.number,
    average_speed: PropTypes.number,
    average_heartrate: PropTypes.number
  }).isRequired
};

export default StravaActivitySummary;
