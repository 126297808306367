import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Markdown from 'react-markdown';

import { generateText } from '../api/llms';

function LLM() {
  const [model, setModel] = useState('gemini-1.5-flash-latest');
  const [prompt, setPrompt] = useState(null);
  const [output, setOutput] = useState(null);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={3}
      xs={12}
      md={6}
    >
      <Grid item xs container direction="column" spacing={2}>
        <Grid item xs>
          <Paper>
            <FormControl variant="standard" sx={{ m: 3, width: '90%' }}>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <InputLabel id="llm-select-label" label="Model" />
                  <Select
                    id="llm-select"
                    labelId="llm-select-label"
                    label="Model"
                    variant="outlined"
                    value={model}
                    onChange={(event) => {
                      setModel(event.target.value);
                    }}
                    sx={{
                      width: '30%'
                    }}
                  >
                    <MenuItem value="gemini-1.5-flash-latest">
                      Gemini 1.5 Flash
                    </MenuItem>
                    <MenuItem value="gpt-4o">GPT-4o</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Prompt"
                    variant="outlined"
                    minRows={3}
                    multiline
                    fullWidth
                    onChange={(event) => {
                      setPrompt(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      console.log(model);
                      console.log(prompt);
                      const result = await generateText(
                        model,
                        undefined,
                        prompt
                      );
                      setOutput(result);
                    }}
                  >
                    Go!
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </Paper>
        </Grid>
        {output && (
          <Grid item xs>
            <Paper>
              <Markdown>{output}</Markdown>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

LLM.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    colourPicker: PropTypes.string
  }).isRequired
};

export default LLM;
