import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  Stack,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';

import StravaSuggestedActivity from './stravaSuggestedActivity';

function StravaSuggestedActivities({ routes, targetTime }) {
  const [selected, setSelected] = useState(0);

  const [minTime, maxTime] = targetTime;

  return (
    <Card>
      <CardContent>
        <Stack useFlexGap>
          {routes && targetTime ? (
            <>
              <Typography variant="h5" component="h2" gutterBottom>
                {`Next Run (${minTime} - ${maxTime} mins)`}
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="demo-simple-select-label">Route</InputLabel>
                <Select
                  value={selected}
                  label="Route"
                  labelId="demo-simple-select-label"
                  onChange={(event) => {
                    setSelected(event.target.value);
                  }}
                  sx={{
                    color: '#fc5200'
                  }}
                >
                  {routes.map((route, i) => (
                    <MenuItem
                      value={i}
                    >{`${route.name} (${(route.distance / 1000).toFixed(1)} km, ${route.estimatedTime.toFixed(0)} mins)`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <StravaSuggestedActivity route={routes[selected]} />
            </>
          ) : (
            <Typography variant="h5" component="h2" gutterBottom>
              {`No route identified${
                targetTime && ` lasting ${minTime} - ${maxTime} mins`
              }`}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

StravaSuggestedActivities.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id_str: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      distance: PropTypes.number.isRequired,
      estimated_moving_time: PropTypes.number.isRequired,
      'map_urls.retina_url': PropTypes.string.isRequired,
      estimatedTime: PropTypes.number.isRequired
    })
  ),
  targetTime: PropTypes.arrayOf(PropTypes.number)
};

StravaSuggestedActivities.defaultProps = {
  routes: null,
  targetTime: null
};

export default StravaSuggestedActivities;
