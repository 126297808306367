/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import Plot from 'react-plotly.js';

import { getColourScale } from '../utils';

export const plotHeartMap = (df, width) => {
  const traces = [{
    x: df.getSeries('Date').toArray(),
    y: df.getSeries('Window').toArray(),
    z: df.getSeries('Heart Rate').toArray(),
    text: df.getSeries('Label').toArray(),
    hoverinfo: 'text',
    name: 'Heart Rate',
    type: 'heatmap',
    colorscale: getColourScale(df, 'Heart Rate')
  }];

  const layout = {
    width,
    showlegend: true,
    xaxis: {
      title: '',
      fixedrange: true
    },
    yaxis: {
      title: '',
      fixedrange: true,
      tickmode: 'array',
      tickvals: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88],
      ticktext: [
        '0:00', '2:00',
        '4:00', '6:00',
        '8:00', '10:00',
        '12:00', '14:00',
        '16:00', '18:00',
        '20:00', '22:00'
      ]
    },
    title: 'Average Heart Rate',
    autosize: true
  };
  
  const config = {
    displayModeBar: false
  };

  return <Plot
    data={traces}
    layout={layout}
    config={config}
  />;
};

export const plotHeartLine = (df, width) => {
  const trace = {
    x: df.getSeries('Date').toArray(),
    y: df.getSeries('Heart Rate').toArray(),
    text: df.getSeries('Label').toArray(),
    hoverinfo: 'text',
    name: 'Heart Rate',
    mode: 'lines',
    line: {
      shape: 'spline',
      color: 'rgb(205, 12, 24)'
    },
    showlegend: false
  };

  const data = [trace];

  const layout = {
    width,
    showlegend: false,
    xaxis: {
        title: ''
    },
    yaxis: {
        title: 'Heart Rate [bpm]',
    },
    title: 'Heart Rate',
    autosize: true
  };

  const config = {
    displayModeBar: false
  }

  return <Plot
    data={data}
    layout={layout}
    config={config}
  />;
};

export const plotSleepBars = (df, width) => {
  const sleepStates = {
    'deep': 'Deep',
    'light': 'Light',
    'rem': 'REM',
    'wake': 'Awake'
  };

  const sleepColours = {
    'Awake': 'rgb(200, 255, 255)',
    'REM': 'rgb(0, 201, 255)',
    'Light': 'rgb(0, 130, 213)',
    'Deep': 'rgb(0, 50, 127)'
  };

  const data = [];

  Object.keys(sleepStates).forEach((fitbitState) => {
    const sleepState = sleepStates[fitbitState];
    const dfState = df.where(row => row.level === fitbitState);
    
    const bars = {
      x: dfState.getSeries('Date').toArray(),
      y: dfState.getSeries('Hours').toArray(),
      text: dfState.getSeries('Label').toArray(),
      textposition: 'none',
      hoverinfo: 'text',
      name: sleepState,
      type: 'bar',
      marker: {
        color: sleepColours[sleepState]
      }
    };

    data.push(bars);
  })

  const layout = {
    width,
    barmode: 'stack',
    showlegend: true,
    xaxis: {
      title: ''
    },
    yaxis: {
      title: 'Duration [hours]',
      fixedrange: true,
    },
    title: 'Sleep States',
    autosize: true
  };

  const config = {
    displayModeBar: false
  }

  return <Plot
    data={data}
    layout={layout}
    config={config}
  />;
};

export const plotStepsBars = (df, width) => {
  const data = [{
    x: df.getSeries('Date').toArray(),
    y: df.getSeries('Steps').toArray(),
    text: df.getSeries('Label').toArray(),
    textposition: 'none',
    hoverinfo: 'text',
    name: 'Steps',
    type: 'bar',
    marker: {
      'color': 'rgb(62, 239, 188)',
    }
  }];

  const layout = {
    width,
    barmode: 'stack',
    showlegend: false,
    xaxis: {
      title: ''
    },
    yaxis: {
      title: 'Number of Steps',
      fixedrange: true,
    },
    title: 'Daily Steps',
    autosize: true
  };

  const config = {
    displayModeBar: false
  }

  return <Plot
    data={data}
    layout={layout}
    config={config}
  />;
};

// function plotStepIntensityBars(df, width) {
//   let intensities = {
//     2: 'Low',
//     3: 'Light',
//     4: 'Medium',
//     5: 'Intense'
//   };

//   let intensityLows = {
//     2: 0,
//     3: 2,
//     4: 3,
//     5: 4
//   };

//   let intensityColours = {
//     2: 'rgb(200, 255, 255)',
//     3: 'rgb(100, 201, 255)',
//     4: 'rgb(0, 201, 255)',
//     5: 'rgb(0, 130, 213)'
//   };

//   const data = [];

//   for (let i in intensities) {
//     let dfState = df.where(row => !isNaN(row.Steps))
//     .where(row => row.Intensity < i && row.Intensity > intensityLows[i]).generateSeries({
//       Day: row => removeTimeFromDate(row.Date)
//     })
//     .groupBy(row => row.Day)
//     .select(group => ({
//       Day: group.first().Day,
//       TotalSteps: group.deflate(row => row.Steps).sum()
//     }))
//     .inflate();

//     // console.log(dfState.toArray());

//     let bars = {
//       x: dfState.getSeries('Day').toArray(),
//       y: dfState.getSeries('TotalSteps').toArray(),
//       text: dfState.generateSeries({
//         Label: row => `${intensities[i]} ${row.TotalSteps} steps`
//       }).getSeries('Label').toArray(),
//       hoverinfo: 'text',
//       name: intensities[i],
//       type: 'bar',
//       marker: {
//         // color: intensityColours[i]
//         color: `rgb(${i * -40 + 200}, ${i * 60 - 50}, 150)`
//       }
//     };

//     data.push(bars);
//   }

//   const layout = {
//     width,
//     barmode: 'stack',
//     showlegend: true,
//     xaxis: {
//       title: ''
//     },
//     yaxis: {
//       title: 'Number of Steps',
//       fixedrange: true,
//     },
//     title: 'Daily Steps',
//     autosize: true
//   };

//   const config = {
//       // responsive: true,
//       displayModeBar: false
//   }

//   return <Plot
//     data={data}
//     layout={layout}
//     config={config}
//   />;
// }

const windowSeries = (series, windowSize) => {
  const shift = Math.floor(windowSize / 2);
  const rolling = series
    .rollingWindow(windowSize)
    .select(window => window.sum() / windowSize)
    .toArray();
  
  const leftPad = new Array(shift);
  return leftPad.concat(rolling);
};

export const plotWeightLine = (df, width) => {
  const xValues = df.getSeries('Date').toArray();
  const yValues = df.getSeries('Weight').toArray();
  const rolling = windowSeries(df.getSeries('Weight'), 10);

  const points = {
    x: xValues,
    y: yValues,
    text: df.getSeries('Label').toArray(),
    hoverinfo: 'text',
    name: 'Weight',
    mode: 'markers',
    marker: {
      symbol: 'x',
      color: 'rgb(12, 84, 132)',
      size: 3
    },
    showlegend: false
  };

  const trend = {
      x: xValues,
      y: rolling,
      hoverinfo: 'none',
      name: 'Weight (moving average)',
      mode: 'lines',
      line: {
        shape: 'spline',
        color: 'rgb(10, 128, 206)'
      },
      showlegend: false
  };

  const data = [points, trend];

  const layout = {
    width,
    showlegend: true,
    xaxis: {
      title: ''
    },
    yaxis: {
      title: 'Weight [kg]',
    },
    title: 'Weight',
    autosize: true
  };

  const config = {
    displayModeBar: false
  }

  return <Plot
    data={data}
    layout={layout}
    config={config}
  />;
};

export const plotBodyFatLine = (df, width) => {
  const xValues = df.getSeries('Date').toArray();
  const yValues = df.getSeries('Fat').toArray();
  const rolling = windowSeries(df.getSeries('Fat'), 10);

  const points = {
      x: xValues,
      y: yValues,
      text: df.getSeries('Label').toArray(),
      hoverinfo: 'text',
      name: 'Body Fat',
      mode: 'markers',
      marker: {
          symbol: 'x',
          color: 'rgb(249, 154, 23)',
          size: 3
      },
      showlegend: false
  };

  const trend = {
      x: xValues,
      y: rolling,
      hoverinfo: 'none',
      name: 'Body Fat (best fit)',
      mode: 'lines',
      line: {
          shape: 'spline',
          color: 'rgb(189, 116, 14)'
      },
      showlegend: false
  };

  const data = [points, trend];

  const layout = {
      width,
      showlegend: true,
      xaxis: {
          title: ''
      },
      yaxis: {
          title: 'Body Fat [%]',
      },
      title: 'Body Fat Percentage',
      autosize: true
  };

  const config = {
      displayModeBar: false
  }

  return <Plot
    data={data}
    layout={layout}
    config={config}
  />;
};

// function dateFromJsDate(date) {
//   let d = date.getDate();
//   let m = date.getMonth();
//   let y = date.getFullYear();

//   return new Date(y, m, d);
// }

// function dateHourFromJsDate(date) {
//   let d = date.getDate();
//   let m = date.getMonth();
//   let y = date.getFullYear();
//   let h = date.getHours();

//   return new Date(y, m, d, h);
// }

// function assignSleepDate(date) {
//   let d = new Date(date.getTime());
//   if (d.getHours() > 14) {
//       d.setHours(0, 0, 0, 0);
//   } else {
//       d.setHours(-24, 0, 0, 0);
//   }
//   return d;
// }

// function removeTimeFromDate(date) {
//   let d = new Date(date.getTime());
//   d.setHours(0, 0, 0, 0);
//   return d;
// }
