import { makeRequest } from './base';

export const getForecast = (address) =>
  makeRequest('POST', 'astro/forecast', { address });
export const getTargets = (address, targets) =>
  makeRequest('POST', 'astro/targets', { address, targets });
export const getTargetVisibilities = (address, targets, startDate, endDate) =>
  makeRequest('POST', 'astro/target-visibilities', {
    address,
    targets,
    start_date: startDate,
    end_date: endDate
  });
export const getAuroraForecast = () =>
  makeRequest('GET', 'astro/aurora/forecast');
export const getAuroraForecastScore = (address) =>
  makeRequest('POST', 'astro/aurora/forecast-score', { address });
export const getAuroraKIndex = () => makeRequest('GET', 'astro/aurora/k-index');
export const getSatellitePasses = (address, satelliteName) =>
  makeRequest('POST', 'astro/satellites/passes', { address, satelliteName });
