import jwtDecode from 'jwt-decode';

const tokenExpired = (token, tokenName = null) => {
  if (!token) return true;

  const decodedToken = jwtDecode(token);
  const now = new Date();
  const expiry = new Date(decodedToken.exp * 1000);
  if (tokenName) {
    console.log(`${tokenName} token expiry: ${expiry}`);
  } else {
    console.log(`Expiry: ${expiry}`);
  }
  return now > expiry;
};

const gSignIn = async (token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/GSignIn`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token
        })
      }
    );
    if (response.status !== 200)
      throw new Error('Non-200 reponse from /auth/GSignIn');

    return response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const saveGoogleLogin = async (incomingResponse) => {
  console.log('Saving Google login...');

  try {
    if (tokenExpired(incomingResponse.credential))
      throw new Error('Google credentials expired');

    const tokenData = await gSignIn(incomingResponse.credential);
    if (!tokenData?.access_token)
      throw new Error('Missing access token in response body');

    localStorage.setItem('api_token', JSON.stringify(tokenData));
    localStorage.setItem(
      'google_token',
      JSON.stringify(incomingResponse.credential)
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const exchangeGoogleToken = async (googleToken) => {
  console.log('Exchanging Google token...');
  console.log(googleToken);

  try {
    const tokenData = await gSignIn(googleToken.id_token);
    console.log(tokenData);
    if ('Error' in tokenData) throw tokenData.Error;

    localStorage.setItem('api_token', JSON.stringify(tokenData));
    return tokenData;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const refreshApiToken = async (existingToken) => {
  console.log('Refreshing API token...');

  const { refresh_token: refreshToken } = existingToken;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/refresh`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${refreshToken}`
        }
      }
    );
    const tokenData = await response.json();
    console.log(tokenData);
    if ('Error' in tokenData) throw tokenData.Error;

    const newTokenData = {
      ...tokenData,
      refresh_token: refreshToken
    };

    localStorage.setItem('api_token', JSON.stringify(newTokenData));

    return { newTokenData };
  } catch (err) {
    console.error(err);
    return null;
  }
};

const tokenHasAccessToken = (tokenData) =>
  tokenData !== null && 'access_token' in tokenData;

const getApiToken = async () => {
  const tokenData = JSON.parse(localStorage.getItem('api_token'));
  if (!tokenHasAccessToken(tokenData)) {
    console.error('API token not found (local storage)');
    return null;
  }

  const accessTokenExpired = tokenExpired(tokenData.access_token, 'Access');

  if (!accessTokenExpired) {
    console.log('Using existing access token');
    return tokenData.access_token;
  }

  const refreshTokenExpired = tokenExpired(tokenData.refresh_token, 'Refresh');

  if (!refreshTokenExpired) {
    // Attempt to refresh API access token using refresh token
    const newTokenData = await refreshApiToken(tokenData);
    if (!tokenHasAccessToken(newTokenData)) {
      console.error('API token not found (refresh)');
      return null;
    }
    console.log('Using refreshed access token');
    return newTokenData.access_token;
  }

  const googleToken = JSON.parse(localStorage.getItem('google_token'));
  const googleTokenExpired = tokenExpired(googleToken, 'Google');

  if (!googleTokenExpired) {
    // Attempt to refresh API tokens using existing Google creds
    const newTokenData = await exchangeGoogleToken(googleToken);
    if (!tokenHasAccessToken(newTokenData)) {
      console.error('API token not found (Google exchange)');
      return null;
    }
    console.log('Using new access token');
    return newTokenData.access_token;
  }

  console.error('Unknown token condition');
  return null;
};

const removeGoogleToken = () => {
  console.log('Removing Google token');
  localStorage.removeItem('google_token');
};

const removeApiTokens = () => {
  console.log('Removing API tokens');
  localStorage.removeItem('api_token');
};

const removeAllTokens = () => {
  removeApiTokens();
  removeGoogleToken();
};

const makeRequest = async (method, endpoint, data) => {
  try {
    const token = await getApiToken();
    if (!token) throw new Error('No API token found');

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: data ? JSON.stringify(data) : undefined
      }
    );

    if (response.status !== 200) {
      const responseBody = await response.text();
      throw new Error(`${response.status} response: ${responseBody}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.error(`Error performing ${method} request to ${endpoint} - ${err}`);
    throw err;
  }
};

export {
  getApiToken,
  saveGoogleLogin,
  refreshApiToken,
  tokenExpired,
  removeGoogleToken,
  removeAllTokens,
  makeRequest
};
