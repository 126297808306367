import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { DataFrame } from 'data-forge';
import Paper from '@material-ui/core/Paper';

import { parseISO, format } from 'date-fns';

import {
  plotHeartMap,
  plotHeartLine,
  plotSleepBars,
  plotStepsBars,
  plotWeightLine,
  plotBodyFatLine
} from '../utils/plotUtils';
import {
  getAuthUrl,
  getGenericData,
  isAuthorised,
  storeAuthInfo
} from '../api/fitbit';

// const genericTransform = (rawData) => {
//   let dataFrame = new DataFrame(rawData);
//   dataFrame = dataFrame.generateSeries({
//     Date: (row) => new Date(row.Date)
//   });

//   return dataFrame;
// };

const weightTransform = (rawData) => {
  let dataFrame = new DataFrame(rawData);
  dataFrame = dataFrame
    .generateSeries({
      Date: (row) => new Date(row.datetime),
      Weight: (row) => row.weight,
      Fat: (row) => row.fat
    })
    .generateSeries({
      Label: (row) =>
        `${row.Weight.toFixed(1)} kg<br />${row.Fat.toFixed(
          1
        )}% fat<br />${format(row.Date, 'EEE do MMM')}`
    });

  return dataFrame;
};

const heartRateTransform = (rawData) => {
  let dataFrame = new DataFrame(rawData);
  dataFrame = dataFrame
    .generateSeries({
      Date: (row) => parseISO(row.datetime),
      'Heart Rate': (row) => row.heartrate
    })
    .generateSeries({
      Label: (row) =>
        `${row['Heart Rate']} bpm<br />${format(row.Date, 'H:mm:ss')}`
    });

  return dataFrame;
};

const heartMapTransform = (rawData) => {
  let dataFrame = new DataFrame(rawData);
  dataFrame = dataFrame
    .generateSeries({
      Date: (row) => parseISO(row.date),
      DateTime: (row) => parseISO(row.datetime),
      Window: (row) => row.time,
      'Heart Rate': (row) => row.heartrate
    })
    .generateSeries({
      Label: (row) =>
        `${row['Heart Rate']} bpm<br />${format(row.DateTime, 'E do MMM H:mm')}`
    });

  return dataFrame;
};

const sleepTransform = (rawData) => {
  const sleepStates = {
    rem: 'REM',
    deep: 'Deep',
    light: 'Light',
    wake: 'Awake'
  };

  let dataFrame = new DataFrame(rawData);
  dataFrame = dataFrame
    .generateSeries({
      Date: (row) => parseISO(row.date),
      Hours: (row) => row.minutes / 60,
      Duration: (row) =>
        `${Math.floor(row.minutes / 60)}:${(row.minutes % 60)
          .toString()
          .padStart(2, '0')}`
    })
    .generateSeries({
      Label: (row) =>
        `${format(row.Date, 'E do MMM')}<br />${sleepStates[row.level]} ${
          row.Duration
        }`
    });

  return dataFrame;
};

const stepsTransform = (rawData) => {
  let dataFrame = new DataFrame(rawData);
  dataFrame = dataFrame
    .generateSeries({
      Date: (row) => parseISO(row.date),
      Steps: (row) => row.steps
    })
    .generateSeries({
      Label: (row) =>
        `${format(
          row.Date,
          'E do MMM'
        )}<br />${row.Steps.toLocaleString()} steps`
    });

  return dataFrame;
};

class HealthDataset {
  constructor(
    endpoint,
    data,
    transformFunction,
    setFunction,
    plotFunction,
    ref
  ) {
    this.endpoint = endpoint;
    this.data = data;
    this.transformFunction = transformFunction;
    this.setData = setFunction;
    this.plot = plotFunction;
    this.ref = ref;
    this.loading = false;
  }

  async getData() {
    if (!this.loading) {
      this.loading = true;
      // console.log(`Fetching data from ${this.endpoint}`);

      try {
        const data = await getGenericData(this.endpoint);

        // console.log(data);
        const dataFrame = this.transformFunction(data);

        this.setData(dataFrame);
        this.loading = false;

        return this.endpoint;
      } catch (err) {
        console.error(err);
        return `!${this.endpoint}`;
      }
    }

    return null;
  }
}

function Health(props) {
  const [plotWidth, setPlotWidth] = useState(null);

  const [heartMap, setHeartMap] = useState(null);
  const [heartRate, setHeartRate] = useState(null);
  const [sleep, setSleep] = useState(null);
  const [dailySummary, setDailySummary] = useState(null);
  const [weight, setWeight] = useState(null);

  const [fitbitAuthorised, setFitbitAuthorised] = useState(null);
  const [fitbitExchangeStarted, setFitbitExchangeStarted] = useState(false);

  const plotRef = useRef(null);

  const datasets = useMemo(
    () => ({
      heartMap: new HealthDataset(
        `fitbit/heartMap`,
        heartMap,
        heartMapTransform,
        setHeartMap,
        plotHeartMap,
        plotRef
      ),
      heartRate: new HealthDataset(
        `fitbit/heartRate`,
        heartRate,
        heartRateTransform,
        setHeartRate,
        plotHeartLine
      ),
      sleep: new HealthDataset(
        `fitbit/sleep`,
        sleep,
        sleepTransform,
        setSleep,
        plotSleepBars
      ),
      steps: new HealthDataset(
        `fitbit/daily-summary`,
        dailySummary,
        stepsTransform,
        setDailySummary,
        plotStepsBars
      ),
      weight: new HealthDataset(
        `fitbit/weight`,
        weight,
        weightTransform,
        setWeight,
        plotWeightLine
      ),
      bodyFat: new HealthDataset(
        `fitbit/weight`,
        weight,
        weightTransform,
        setWeight,
        plotBodyFatLine
      )
    }),
    [heartMap, heartRate, sleep, dailySummary, weight]
  );

  const checkFitbitAuthorised = async () => {
    console.log('Checking Fitbit authorisation');

    try {
      const data = await isAuthorised();

      if (data.authorised === true) {
        setFitbitAuthorised(data.authorised);
      } else {
        console.error('User not authorised with Fitbit');
        setFitbitAuthorised(false);
      }
    } catch (err) {
      console.error(err);
      setFitbitAuthorised(false);
    }
  };

  const persistFitbitCreds = async (code) => {
    if (!fitbitExchangeStarted) {
      setFitbitExchangeStarted(true);

      console.log('Storing Fitbit credentials');

      const data = await storeAuthInfo(code);
      console.log(data);
      checkFitbitAuthorised();
    }
  };

  useEffect(() => {
    if (fitbitAuthorised === null) {
      checkFitbitAuthorised();
    } else if (fitbitAuthorised) {
      if (Object.values(datasets).every((dataset) => dataset.data === null)) {
        const uniqueEndpoints = [];
        const uniqueDatasets = [];

        Object.entries(datasets).forEach(([, dataset]) => {
          if (!uniqueEndpoints.includes(dataset.endpoint)) {
            uniqueEndpoints.push(dataset.endpoint);
            uniqueDatasets.push(dataset);
          }
        });

        const chartPromises = uniqueDatasets.map((dataset) =>
          dataset.getData()
        );
        Promise.all(chartPromises).then((allData) => {
          console.log(allData);
        });
      }
    }
  }, [fitbitAuthorised]);

  useEffect(() => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth, datasets, plotRef]);

  window.addEventListener('resize', () => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  const { location } = props;
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const code = params.get('code');

  if (code && !fitbitAuthorised) {
    console.log(code);
    persistFitbitCreds(code);
  }

  const progress = (
    <div
      style={{
        height: '457px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={100} />
    </div>
  );

  return (
    <>
      {!fitbitAuthorised && (
        <Button
          variant="contained"
          onClick={async () => {
            const { authUrl } = await getAuthUrl();
            window.location.replace(authUrl);
          }}
        >
          Fitbit Login
        </Button>
      )}
      <Grid container spacing={5}>
        {Object.keys(datasets).map((key) => {
          const { ref, data, plot } = datasets[key];

          return (
            <Grid key={key} item xs={12} lg={6}>
              <Paper ref={ref}>{data ? plot(data, plotWidth) : progress}</Paper>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

Health.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default Health;
