import { makeRequest } from './base';

const userAuthorised = () => makeRequest('GET', 'health/strava/isAuthorised');
const storeAuthInfo = (code, scopes, state) => {
  const reqParams = new URLSearchParams({
    code,
    scopes,
    state
  });

  const endpoint = `health/strava/login?${reqParams}`;
  return makeRequest('GET', endpoint);
};

const getActivities = (data) =>
  makeRequest('POST', 'health/strava/activities', data);

const getActivityData = (activityId) =>
  makeRequest('GET', `health/strava/activity/${activityId}`);

const getRoutes = () => makeRequest('POST', 'health/strava/routes');

export {
  userAuthorised,
  storeAuthInfo,
  getActivities,
  getActivityData,
  getRoutes
};
